<template>
  <div style="background-color: #f6faff;padding-top: 25px;padding-bottom: 110px">
    <div class="center">
      <!--左边区域-->
      <div style="margin-right: 20px;width: 760px;float: left;background-color: #fff;border-radius: 10px;box-shadow:1px 1px 11px 1px #e6eff7">
        <!--        分类标签-->
<!--        <div class="type-body">-->
<!--          <div v-for="item  in typeList" :key="item.id" :class="{'type-body-item-select':selectTypeId===item.id}"-->
<!--               @click="setSelectType(item.id)">-->
<!--            {{item.name}}-->
<!--          </div>-->
<!--        </div>-->
        <!--        城市选择-->
        <div class="city-body">
          <Select placeholder="请选择省" :disabled="disableSelectArea" v-model="selectProvinceCode" clearable
                  @on-clear="onClearSelectProvince"
                  @on-change="onSelectProvince">
            <Option v-for="item in provinceList" :value="item.provinceCode" :key="item.provinceCode">
              {{item.provinceName}}
            </Option>
          </Select>
          <Select placeholder="请选择市" :disabled="disableSelectArea" v-model="selectCityCode" clearable
                  style="margin-left: 20px"
                  @on-clear="onClearSelectCity"
                  @on-change="onSelectCity">
            <Option v-for="item in cityList" :value="item.cityCode" :key="item.cityCode">
              {{item.cityName}}
            </Option>
          </Select>
          <Select placeholder="请选择区/县" :disabled="disableSelectArea" v-model="selectAreaCode" clearable
                  style="margin-left: 20px"
                  @on-clear="onClearSelectArea"
                  @on-change="onSelectArea">
            <Option v-for="item in areaList" :value="item.areaCode" :key="item.areaCode">
              {{item.areaName}}
            </Option>
          </Select>
        </div>
        <!--            列表-->
        <div class="science--policy">

          <div class="sc-main">
            <!--              列表左边标签筛选-->
<!--            <div class="sc-main-left">-->
<!--              <div @click="setLabelName(-1)" :class="['sml-font','ellipse-1',{'sml-font-select':-1===selectLabelId}]">-->
<!--                全部-->
<!--              </div>-->
<!--              <div-->
<!--                @click="setLabelName(item)"-->
<!--                :class="['sml-font','ellipse-1',{'sml-font-select':item===selectLabelId}]" v-for="item in labelList"-->
<!--                :key="item">{{item}}-->
<!--              </div>-->

<!--            </div>-->
            <!--            列表右边主体-->
            <div class="sc-main-right">
              <List item-layout="vertical" :split="false" :loading="loading" style="min-height: 400px">
                <ListItem v-for="item in list" style="padding: 0" :key="item.id">
                  <router-link :to="{path: 'sciencePolicyDetail',query:{'id':item.id}}" target="_blank">
                    <div class="smr-body">
                      <div class="smr-body-title ellipse-1">{{item.title}}</div>
                      <div class="smr-body-des">
                        <div>{{item.provinceCode}}{{item.cityCode}}{{item.areaCode}}</div>
                        <div> {{item.updatedTime}}</div>
                      </div>
                    </div>
                  </router-link>
                </ListItem>
              </List>

              <div style="width: 100%;padding-bottom: 20px;padding-right: 20px">
                <Page @on-change="onPageChange" :current="pageNum" :page-size="10" :total="totalNum" show-elevator show-total
                      style="margin-top: 20px;float: right"/>
              </div>

            </div>
          </div>


        </div>

      </div>


      <!--右边区域-->
      <patent-component/>
    </div>
  </div>
</template>

<script>
  import newestPatentAndAchievement from "./newestPatentAndAchievement";
  import {
    getSciencePolicyLabelList,
    getSciencePolicyList,
    getSciencePolicyTypeList
  } from "../../plugins/api/sciencePolicyApi";
  import {getAreaListByCityCode, getCityListByProvinceCode, getProvinceList} from "../../plugins/api/regionApi";

  export default {
    name: "policy",
    data() {
      return {
        list: [],//科技政策列表
        totalNum: 0,//总条数
        typeList: [],//分类列表
        labelList: [],//标签列表
        selectTypeId: -1,//选择的分类ID
        selectLabelId: -1,//选择的标签ID
        provinceList: [],//省份列表
        cityList: [],//城市列表
        areaList: [],//区列表
        selectProvinceCode: -1,//选择的省份编码
        selectCityCode: -1,//选择的城市编码
        selectAreaCode: -1,//选择的区编码
        loading: true,//列表是否加载中
        pageNum: 1,//当前页
        disableSelectArea: true,//是否禁用选择地址
      }
    },
    mounted() {
      this.getLabelList();
      this.getTypeList();
      this.getProvinceList();
    },
    components: {
      patentComponent: newestPatentAndAchievement,
    },
    methods: {
      //获取分类列表
      async getTypeList() {
        const json = await getSciencePolicyTypeList().catch(() => {
          this.disableSelectArea = false;
          this.loading = false
        });
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.typeList = json.result.list;
            this.selectTypeId = json.result.list[0].id;
            this.getSciencePolicyList();
          }
        }
        this.disableSelectArea = false;
        this.loading = false
      },

      //设置分类id
      setSelectType(id) {
        this.selectTypeId = id;
        this.getSciencePolicyList();
      },

      //设置标签名称
      setLabelName(name) {
        this.selectLabelId = name;
        this.getSciencePolicyList();
      },

      //获取标签列表
      async getLabelList() {
        const json = await getSciencePolicyLabelList();
        if (json && json.code === 0 && json.result && json.result.length > 0) {
          this.labelList = json.result;

        }
      },

      //获取省份列表
      async getProvinceList() {
        const json = await getProvinceList();
        if (json && json.code === 0) {
          if (json.result && json.result.length > 0) {
            this.provinceList = json.result
          }
        }
      },

      //获取城市列表
      async getCityList(provinceCode) {
        const params = {
          provinceCode: provinceCode
        }
        const json = await getCityListByProvinceCode(params);
        if (json && json.code === 0) {
          if (json.result && json.result.length > 0) {
            this.cityList = json.result
          }

        }
      },

      //获取区列表
      async getAreaList(cityCode) {
        const params = {
          cityCode: cityCode
        }
        const json = await getAreaListByCityCode(params)
        if (json && json.code === 0) {
          if (json.result && json.result.length > 0) {
            this.areaList = json.result
          }

        }

      },

      //清除选择省份时触发
      onClearSelectProvince() {
        this.selectProvinceCode = -1;
        this.selectCityCode = -1;
        this.selectAreaCode = -1;
        this.cityList = [];
        this.areaList = [];
        this.getSciencePolicyList();
      },

      //选择省份时触发
      onSelectProvince(value) {
        this.cityList = [];
        this.areaList = [];
        this.selectCityCode = -1;
        this.selectAreaCode = -1;
        if (!value) {
          return
        }
        this.getCityList(value);
        this.getSciencePolicyList();
      },

      //选择城市时触发
      onSelectCity(value) {
        this.areaList = [];
        this.selectAreaCode = -1;
        if (!value) {
          return
        }
        this.getAreaList(value);
        this.getSciencePolicyList();
      },

      //清除选择城市时触发
      onClearSelectCity() {
        this.selectCityCode = -1;
        this.selectAreaCode = -1;
        this.areaList = [];
        this.getSciencePolicyList();
      },

      //选择区时触发
      onSelectArea(value) {
        if (!value) {
          return;
        }
        this.getSciencePolicyList();
      },
      //清除区时触发
      onClearSelectArea() {
        this.selectAreaCode = -1;
        this.getSciencePolicyList();
      },

      //获取科技政策列表
      async getSciencePolicyList() {
        this.disableSelectArea = true;
        this.loading = true;
        this.list = [];
        const {pageNum, selectProvinceCode, selectCityCode, selectAreaCode, selectTypeId, selectLabelId} = this;
        const params = {
          pageSize: 10,
          pageNum: pageNum,
          provinceCode: selectProvinceCode,
          cityCode: selectCityCode,
          areaCode: selectAreaCode,
        }
        const json = await getSciencePolicyList(params).catch(() => {
          this.disableSelectArea = false;
          this.loading = false
        });
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.list = json.result.list;
            this.totalNum = json.result.total;
          }
        }
        this.disableSelectArea = false;
        this.loading = false
      },

      //监听分页变化
      onPageChange(pageNum) {
        this.pageNum = pageNum;
        this.getSciencePolicyList();
      },
    }
  }
</script>

<style scoped lang="scss">

/deep/ .ivu-page-item-active {
  background-color: #1890FF;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}
  .center {
    width: 1200px;
    margin: 0 auto;
    display: table;
  }

  .type-body {
    background-color: #FFFFFF;
    height: 50px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E8E8E8;
    padding: 0 15px;
  }

  .type-body div {
    height: 49px;
    font-size: 14px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
  }

  .type-body-item-select {
    color: #FF6A00;
    border-bottom: 2px solid #FF6A00;
  }

  .city-body {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E8E8E8;
    background-color: #FFFFFF;
    height: 60px;
    align-items: center;
    padding:0 20px;
    position: relative;
    border-radius: 10px 10px 0 0;
  }


  .science--policy {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .sc-main {
    display: flex;
    flex-direction: row;
  }

  .sc-main-left {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
  }

  .sml-font {
    height: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    width: 150px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    border-bottom: 1px solid #E8E8E8;
  }

  .sml-font-select {
    background-color: #FF6A00;
    color: #FFFFFF;
  }

  .sc-main-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*overflow: hidden;*/
  }

  .smr-body {
    padding: 20px 20px;
    cursor: pointer;
    border-bottom: 1px solid #E8E8E8;
    &:hover{
      .smr-body-title {
        color: #1767E0;
      }
      box-shadow: 1px 1px 11px 1px #2a8bef;
    }
  }

  .smr-body-title {
    font-size: 18px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;
  }

  .smr-body-des {
    color: #859BBD;
    font-size: 14px;
    > div{
      margin-top: 15px;
    }
  }

</style>
